import ApiHelper from './index';

const fetchCycleWiseMissedSteps = async (spinner = true, queryParams) => {
  return await ApiHelper.get(
    'telemetry/cycle_wise_missed_steps/',
    spinner,
    queryParams
  );
};
const fetchCycleWiseMissedStepsV2 = async (spinner = true, queryParams) => {
  return await ApiHelper.get(
    'telemetry/cycle_wise_missed_steps/v2',
    spinner,
    queryParams
  );
};
const fetchCycleDetails = async (spinner = true, cycleIdentifier) => {
  return await ApiHelper.get(
    `telemetry/cycle_details/${cycleIdentifier}`,
    spinner
  );
};
const fetchCycleDetailsV2 = async (
  spinner = true,
  cycleIdentifier,
  deviceId,
  taskId
) => {
  return await ApiHelper.get(
    `telemetry/cycle_details/v2/${cycleIdentifier}/${deviceId}/${taskId}`,
    spinner
  );
};

export default {
  fetchCycleWiseMissedSteps,
  fetchCycleDetails,
  fetchCycleWiseMissedStepsV2,
  fetchCycleDetailsV2,
};
